<!--
 * @Author: yanmin
 * @Description: 支付页面
 * @Date: 2022-04-14 14:42:50
 * @LastEditTime: 2023-07-27 14:53:12
 * @FilePath: \yf_car\src\views\pay.vue
-->
<!--  -->
<template>
  <div class="g-tips-box">
    <img src="../assets/images/tips-img-payok.png" />
    <strong>支付成功</strong>
    <!-- <div class="g-foot-box">
      <p class="u-updata-tips">若不抬杆，请拨打停车场服务热线</p> -->
      <!-- <footdiv></footdiv> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { removeObjNull, browserName, urlEncode, urlParse } from '@/utils/base'
import { setToken, getToken, removeToken, setSmarkId, removeSmarkId, setParklogId, removeParklogId } from '@/utils/auth'
import { Button } from 'vant'
export default {
  components: {
    Button,
  },
  data() {
    return {
      title: '停车时长 12小时3分钟',
      // formData: {
      //     passageway_id: 236,
      //     smartpark_id: '',
      //     consume_id: '',
      //     from: 'wx',
      //     h5_url: 'http://192.168.80.111/pay'
      // }
    }
  },
  computed: {},
  watch: {},
  methods: {
    popstate() {
      // console.log('11')
    },
  },
  created() {
    // window.addEventListener('popstate', this.popstate, false)
  },
  mounted() {
    // let url =
    //     "http://h5.car2-test.yf5g.net/carlogin.html?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOnsiaWQiOjEzLCJmcm9tIjoid3giLCJuaWNrbmFtZSI6Ilx1NjYxNFx1NWU3NCIsImF2YXRhciI6Imh0dHBzOlwvXC90aGlyZHd4LnFsb2dvLmNuXC9tbW9wZW5cL3ZpXzMyXC9RMGo0VHdHVGZUSUR5U0VkWng5bElFWlk4QkZ3YXRMSHBJczNzcXF5eHlLaGljTWlidmZvMFNXdlQ3cXh5bXRBbDkyZzROT2ExRnZiaWJ5aWFvdGZvWWJsWUFcLzEzMiJ9fQ.TcbT2dr5RsKHnd63uW9a3qeaHL0d-HUTjDmB7I-Wpnk"
    // console.log(111, urlParse(url))
    // const {
    //     token
    // } = urlParse(url)
    // setToken(token)
    // if (!getToken()) {
    //     let strName = urlEncode(removeObjNull(this.formData))
    //     window.location.href = "http://h5.api-car2-test.yf5g.net/v1.oauth/redirectOauth?" + strName
    // } else {
    // }
    // console.log(1111, removeObjNull({
    //     passageway_id: 236,
    //     smartpark_id: '',
    //     consume_id: '',
    //     from: 'wx',
    //     h5_url: 'http://localhost/pay'
    // }))
    // let strName = urlEncode({
    //     passageway_id: 236,
    //     // smartpark_id: '',
    //     // consume_id: '',
    //     from: 'wx',
    //     h5_url: 'http://192.168.80.111/pay'
    // })
    // console.log(1111, "http://h5.api-car2-test.yf5g.net/v1.oauth/redirectOauth?" + strName)
    // window.location.href = "http://h5.api-car2-test.yf5g.net/v1.oauth/redirectOauth?" + strName
    // window.location.replace("http://h5.api-car2-test.yf5g.net/v1.oauth/redirectOauth?" + strName)
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    // window.removeEventListener('popstate', this.popstate, false)
  },
  activated() {},
}
</script>
<style lang="scss" scoped>
.g-tips-box {
  width: 100%;
  height: auto;
  background: #fff;
  display: block;
  overflow: hidden;
  padding: 1.14rem 0;
  margin: 0;
}

.g-tips-box img {
  width: 2.6rem;
  height: auto;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.g-tips-box strong {
  width: 100%;
  height: 0.44rem;
  line-height: 0.44rem;
  font-size: 0.38rem;
  font-weight: normal;
  color: #222;
  text-align: center;
  margin: 0.38rem 0 0;
  display: block;
  overflow: hidden;
}
</style>
